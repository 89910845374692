import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { StoreProvider, initStore } from "./store/store";
import "./styles/globals.scss";
import Layout from "./components/Layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = initStore({});
root.render(
	<>
		{store && (
			<StoreProvider store={store}>
				<Suspense fallback={<>...</>}>
					<Layout />
				</Suspense>
			</StoreProvider>
		)}
	</>,
);
