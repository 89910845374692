/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import css from "../styles/DesignSystem.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "../i18next";

import Logo from "./elements/Logo";
import Welcome from "./Home/Welcome";
import MakeCash from "./Home/MakeCash";
import Team from "./Home/Team";

const BurgerMenu = () => {
	const [isOpened, setIsOpened] = useState(false);
	const { i18n, t } = useTranslation();

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
	};

	const scrollTo = id => {
		const el = document.getElementById(id);

		if (el) {
			setIsOpened(false);
			el.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<>
			<div className={css.burgerContainer}>
				<div
					className={classNames(
						css.uiHeader_burger,
						css.uiHeader_burger_icon,
						isOpened && css._opened,
					)}
					onClick={() => setIsOpened(!isOpened)}
				>
					<div className={classNames(css.uiHeader_burger_icon__bar, css._1)} />
					<div className={classNames(css.uiHeader_burger_icon__bar, css._2)} />
					<div className={classNames(css.uiHeader_burger_icon__bar, css._3)} />
					<div className={classNames(css.uiHeader_burger_icon__bar, css._4)} />
				</div>
				<div className={css.languages}>
					<span
						className={classNames(i18n.language === "ru" && css._active)}
						onClick={() => changeLanguage("ru")}
					>
						RU
					</span>
					<span
						className={classNames(i18n.language === "ua" && css._active)}
						onClick={() => changeLanguage("ua")}
					>
						UA
					</span>
					<span
						className={classNames(i18n.language === "en" && css._active)}
						onClick={() => changeLanguage("en")}
					>
						EN
					</span>
				</div>
			</div>

			<aside className={classNames(css.uiHeader_burger__wrapper, isOpened && css._opened)}>
				<div className={css.burgerHeader}>
					<div className={css.burgerLogo} />
					<div className={css.burgerClose} onClick={() => setIsOpened(false)} />
				</div>
				<div className={css.burgerBody}>
					<ul className={css.menu}>
						<li className={css.item} onClick={() => scrollTo("welcome")}>
							{t("header.welcome")}
						</li>
						<li className={css.item} onClick={() => scrollTo("makeCash")}>
							{t("header.aboutUs")}
						</li>
						<li className={css.item} onClick={() => scrollTo("team")}>
							{t("header.team")}
						</li>
					</ul>
					<div className={css.other}>
						<div className={css.languagesBurger}>
							<span
								className={classNames(i18n.language === "ru" && css._active)}
								onClick={() => changeLanguage("ru")}
							>
								Ru
							</span>
							<span
								className={classNames(i18n.language === "ua" && css._active)}
								onClick={() => changeLanguage("ua")}
							>
								Ua
							</span>
							<span
								className={classNames(i18n.language === "en" && css._active)}
								onClick={() => changeLanguage("en")}
							>
								En
							</span>
						</div>
						<button
							className={css.burgerButton}
							onClick={() => (window.location.href = `${process.env.REACT_APP_TELEGRAM}`)}
						>
							{t("home.joinTeam")}
						</button>
					</div>
				</div>
			</aside>
		</>
	);
};

const Layout = ({ children }) => {
	return (
		<div className={css.uiContainer} id="container">
			<section className={css.uiHeader} id={"header"}>
				<BurgerMenu />
				<div className={css.uiLogo} id="logo">
					<Logo />
				</div>
				<div className={css.uiSocialNetworks}>{/* <div className={css.instagram} /> */}</div>
			</section>
			<section className={css.uiLogoHeader}>
				<div className={classNames(css.uiLogo, css.logoFixed)}>
					<Logo />
				</div>
			</section>
			<Welcome />
			<MakeCash />
			<Team />
			<section className={css.uiFooter}>
				<div className={css.footerLogo} />
				<span className={css.patent}>©2023</span>
			</section>
		</div>
	);
};

export default observer(Layout);
