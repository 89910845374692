import { makeAutoObservable } from "mobx";
import { enableStaticRendering } from "mobx-react";
import { makePersistable, hydrateStore } from "mobx-persist-store";
import { createContext, useContext } from "react";

enableStaticRendering(typeof window === "undefined");

let store;
const server = typeof window === "undefined";

class Store {
	// isCursorPointer = false;
	// isCursorBlur = false;
	// isCursorHint = false;
	// isCursorButton = false;
	hintText = "";
	isMobile = null;
	cursorPosition = [39, 34];
	screenWidth = null;
	toggled = false;
	columnsRows = [0, 0];
	tile = 0;

	constructor() {
		// console.log('store::constructor');
		makeAutoObservable(this, {}, { autoBind: true });

		if (!server) {
			makePersistable(this, {
				name: "appState",
				properties: ["user", "theme", "subscription", "cart"],
				storage: window.localStorage,
				removeOnExpiration: true,
				// debugMode: true,
			}).then(() => {
				console.log("store::persistable");
			});
		}
	}

	handleResize(value) {
		if (!value) {
			const width = window.innerWidth;
			this.screenWidth = width;
			if (width > 1000) {
				this.isMobile = false;
				return;
			}
			if (width <= 600) {
				this.isMobile = "mobile";
				return;
			}
			this.isMobile = "tablet";
			return;
		}
		this.isMobile = value;
	}

	/**
	 * #Set cursor style
	 * @param isPointer {boolean}
	 * @param isBlur {boolean?}
	 * @param isHint {boolean?}
	 * @param isButton {boolean?}
	 */
	// setCursor(isPointer, isBlur, isButton, isHint, hintText) {
	// 	this.isCursorPointer = isPointer;
	// 	if (isPointer && isBlur !== null && !isHint && !isButton) {
	// 		this.isCursorBlur = isBlur;
	// 	} else if (isPointer && isBlur !== null && isHint) {
	// 		this.isCursorHint = isHint;
	// 		this.hintText = hintText;
	// 	} else if (isPointer && isBlur !== null && isButton) {
	// 		this.isCursorButton = isButton;
	// 	} else {
	// 		this.isCursorBlur = false;
	// 		this.isCursorHint = false;
	// 		this.isCursorButton = false;
	// 		this.hintText = "";
	// 	}
	// }

	setToggled() {
		return (this.toggled = !this.toggled);
	}

	setColumnsRows(config) {
		return (this.columnsRows = config);
	}

	setTile(index) {
		return (this.tile = index);
	}

	setCursorPosition(position) {
		// console.log('position', position);
		this.cursorPosition = position;
	}

	async hydrate(initialProps) {
		// console.log('store::hydrate')
		Object.keys(initialProps).forEach(key => {
			// console.log('store::hydrate, adding initial');
			// console.log(key, initialProps[`${key}`]);
			this[`${key}`] = initialProps[`${key}`];
		});
		await hydrateStore(this);
	}
}

const initialize = initialProps => {
	const _store = store ?? new Store();
	if (initialProps) {
		(async () => {
			await _store.hydrate(initialProps);
		})();
	}
	return _store;
};

const StoreContext = createContext(null);

const StoreProvider = ({ store, children }) => {
	return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

const initStore = initialProps => {
	// console.log('store::initStore');
	// console.log('initialProps', initialProps);
	return initialize(initialProps);
};

const useStore = () => {
	return useContext(StoreContext);
};

export { StoreProvider, initStore, useStore, Store };

// export default new Store();
