/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import css from "../../styles/Home.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react";

const Logo = () => {
	return (
		<h1 className="ml2" data-atropos-offset="5">
			<span className={classNames(css.logoGroupe1, "logoGroupe1")}>
				<span className="letter">T</span>
				<span className="letter">R</span>
				<span className="letter">A</span>
				<span className="letter">F</span>
				<span className="letter">F</span>
			</span>
			<span className={classNames(css.logoGroupe2, "logoGroupe2")}>
				<span className="letter">H</span>
				<span className="letter">U</span>
				<span className="letter">N</span>
				<span className="letter">T</span>
			</span>
		</h1>
	);
};

export default observer(Logo);
