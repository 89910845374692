/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import css from "../../styles/MakeCash.module.scss";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "../../i18next";

import { useAnimate, useInView } from "framer-motion";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
	dots: true,
	lazyLoad: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 2,
	autoplay: true,
	autoplaySpeed: 2000,
};

const MakeCash = () => {
	const [animationActive, setAnimationActive] = useState(false);
	const { t } = useTranslation();

	const [scope, animate] = useAnimate();
	const isInView = useInView(scope);

	const cards = [
		{
			stat: "90+",
			title: t("makeCash.cardTitle1"),
			text: t("makeCash.cardText1"),
		},
		{
			stat: "8+",
			title: t("makeCash.cardTitle2"),
			text: t("makeCash.cardText2"),
		},
		{
			stat: "∞",
			title: t("makeCash.cardTitle3"),
			text: t("makeCash.cardText3"),
		},
	];

	useEffect(() => {
		if (isInView) {
			setAnimationActive(true);
		} else {
			setAnimationActive(false);
		}
	}, [isInView]);

	return (
		<section className={css.makeCash} id="makeCash">
			<div className={"titleContainer"}>
				<p className={"title"}>
					{t("makeCash.title1.step1")} <span>{t("makeCash.title1.step2")}</span>
					<br /> {t("makeCash.title1.step3")}
				</p>
			</div>
			<div className={css.buttonContainer}>
				<div className={css.buttonAboutUs}>
					<div className={css.buttonTextWrapper}>
						<div className={css.buttonText}>{t("makeCash.button")}</div>
					</div>
				</div>
			</div>
			<div className={css.cardsContainer}>
				{cards.map((el, i) => {
					return (
						<div className={css.card} key={i}>
							<div className={css.background} />
							<div className={css.title}>
								<div className={classNames(css.icon, css[`icon${i}`])}></div>
								<div className={classNames(css.stats, css[`stats${i}`])}>{el.stat}</div>
							</div>
							<div>
								<h3 className={css.title_2}>{el.title}</h3>
								<p className={css.text}>{el.text}</p>
							</div>
							<div className={css.goTeam}>
								<span onClick={() => (window.location.href = `${process.env.REACT_APP_TELEGRAM}`)}>
									{t("makeCash.buttonCard")}
								</span>
							</div>
						</div>
					);
				})}
			</div>
			<div className={css.slickContainer}>
				<Slider {...settings}>
					{cards.map((el, i) => {
						return (
							<div className={css.cardContainer} key={i}>
								<div className={css.card} key={i}>
									<div className={css.background} />
									<div className={css.title}>
										<div className={classNames(css.icon, css[`icon${i}`])}></div>
										<div className={classNames(css.stats, css[`stats${i}`])}>{el.stat}</div>
									</div>
									<div>
										<h3 className={css.title_2}>{el.title}</h3>
										<p className={css.text}>{el.text}</p>
									</div>
									<div className={css.goTeam}>
										<span
											onClick={() => (window.location.href = `${process.env.REACT_APP_TELEGRAM}`)}
										>
											{t("makeCash.buttonCard")}
										</span>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		</section>
	);
};

export default observer(MakeCash);
