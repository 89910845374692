/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import css from "../../styles/Team.module.scss";
import classNames from "classnames";
import { useStore } from "../../store/store";
import { observer } from "mobx-react";
import { useAnimate, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import "../../i18next";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
	dots: true,
	lazyLoad: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 2,
	autoplay: true,
	autoplaySpeed: 2000,
};

const Partners = () => {
	const store = useStore();

	const [tooltipActive, setTooltipActive] = useState(0);

	const [animationActive, setAnimationActive] = useState(false);

	const [scope, animate] = useAnimate();
	const isInView = useInView(scope);
	const { t } = useTranslation();

	const list = [
		{
			title: "INFLUENCE",
			text: t("team.block1"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNiIgaGVpZ2h0PSIxMzMiIHg9Ii41IiB5PSIuNSIgZmlsbD0idXJsKCNhKSIgc3Ryb2tlPSIjMDAwIiByeD0iNjYuNSIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiNFNzNDMkIiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
		{
			title: "FACEBOOK",
			text: t("team.block2"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiMwOTVDRTkiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
		{
			title: "FINANCIAL",
			text: t("team.block3"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiMwNEE3NzAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
		{
			title: "CONTENT",
			text: t("team.block4"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiNGOUJCMDQiIHN0b3Atb3BhY2l0eT0iLjgzIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLW9wYWNpdHk9IjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L3N2Zz4=")',
		},
		{
			title: "SALES",
			text: t("team.block5"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiNGRjVBRTciLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
		{
			title: "HR",
			text: t("team.block6"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiMyRkJGQ0QiLz48c3RvcCBvZmZzZXQ9Ii4zMjMiIHN0b3AtY29sb3I9IiNEMTE0QTYiIHN0b3Atb3BhY2l0eT0iLjQ0Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLW9wYWNpdHk9IjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L3N2Zz4=")',
		},
		{
			title: "CREATIVE",
			text: t("team.block7"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiNFRDcyMDkiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
		{
			title: "DEVELOPMENT",
			text: t("team.block8"),
			grad: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzciIGhlaWdodD0iMTM0IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgNDM3IDEzNCI+PHJlY3Qgd2lkdGg9IjQzNyIgaGVpZ2h0PSIxMzQiIGZpbGw9InVybCgjYSkiIHJ4PSI2NyIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjQzNyIgeDI9IjQ0LjczNiIgeTE9IjEzNCIgeTI9Ii04MS40NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIHN0b3AtY29sb3I9IiNFRDA5NzYiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==")',
		},
	];

	useEffect(() => {
		if (isInView) {
			setAnimationActive(true);
		} else {
			setAnimationActive(false);
		}
	}, [isInView]);

	return (
		<section className={css.team} id="team">
			<div className={"titleContainer"}>
				<p className={"title"}>
					{t("team.title1.step1")} <span>{t("team.title1.step2")}</span>
					<br /> {t("team.title1.step3")}
				</p>
			</div>
			<ul className={css.list}>
				{list.map((el, i) => {
					return (
						<li className={css.item} style={{ backgroundImage: el.grad }} key={i}>
							<div className={classNames(css.logoContainer, css[`logoContainer_${i + 1}`])} />
							<div className={css.infoContainer}>
								<h4>{el.title}</h4>
								<p>{el.text}</p>
							</div>
						</li>
					);
				})}
			</ul>
			<div className={css.slickContainer}>
				<Slider {...settings}>
					<div className={css.slide}>
						{list.slice(0, Math.ceil(list.length / 2)).map((el, i) => {
							return (
								<li className={css.item} style={{ backgroundImage: el.grad }} key={i}>
									<div className={classNames(css.logoContainer, css[`logoContainer_${i + 1}`])} />
									<div className={css.infoContainer}>
										<h4>{el.title}</h4>
										<p>{el.text}</p>
									</div>
								</li>
							);
						})}
					</div>
					<div className={css.slide}>
						{list.slice(Math.ceil(list.length / 2)).map((el, i) => {
							return (
								<li className={css.item} style={{ backgroundImage: el.grad }} key={i}>
									<div className={classNames(css.logoContainer, css[`logoContainer_${i + 5}`])} />
									<div className={css.infoContainer}>
										<h4>{el.title}</h4>
										<p>{el.text}</p>
									</div>
								</li>
							);
						})}
					</div>
				</Slider>
			</div>
			<div className={css.joinTeam}>
				<button onClick={() => (window.location.href = `${process.env.REACT_APP_TELEGRAM}`)}>
					{t("home.joinTeam")}
				</button>
				<span>{t("team.linkHr")}</span>
			</div>
		</section>
	);
};

export default observer(Partners);
